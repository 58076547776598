import { User } from "lucide-react";
import PageHeader from "../../../componentsV2/local/pageHeader";
import { useCallback, useState } from "react";
import { Button } from "../../../componentsV2/ui/button";
import { Textarea } from "../../../componentsV2/ui/textarea";
import FileUploader from "../../../componentsV2/local/FileUploader";
import { whitelistWithCsvData } from "../../../services/whitelistRecService";
import CSVTable from "../../../componentsV2/local/CsvViewer";
import { Label } from "../../../componentsV2/ui/label";
import { getErrorMessage } from "../../../services/utils";

function WhitelistRecs() {
  const [remarks, setRemarks] = useState("");

  const handleFileSelected = file => {
    console.log("File selected:", file.name);
  };
  const handleRemarksChange = e => {
    const value = e.target.value;
    setRemarks(value);
  };

  const handleValidationPassed = file => {
    console.log("validation Passed", file);
  };

  const handleValidationFailed = error => {
    console.log("validation failed", error);
  };

  const customValidation = file => {
    return null;
  };

  const handleFileReset = useCallback(() => {
    // setUploadedFile(null);
    console.log("resetting remarks");
    setRemarks("");
  }, []);

  const handleSubmit = useCallback(
    async uploadedFile => {
      if (uploadedFile) {
        try {
          await whitelistWithCsvData(uploadedFile, remarks);
          alert(`File Uploaded Successfully-${uploadedFile?.name}`);
        } catch (err) {
          alert(getErrorMessage(err));
        }
        console.log("here", uploadedFile);
        handleFileReset();
      }
    },
    [handleFileReset, remarks]
  );

  return (
    <>
      <div className="sticky top-0 z-50 bg-white">
        <PageHeader heading="Whitelist Recruiters" icon={<User />} />
              </div>

        <div className="p-4">
          <Label>Remarks</Label>
          <Textarea 
            placeholder="Enter remarks (max 250 characters)"
            value={remarks}
            onChange={handleRemarksChange}
            maxLength="250"
          />

          <div className="row space-x-10 px-3 space-y-12">
            <div className=" mt-4 ">
              <FileUploader
                acceptedFileType="csv"
                customValidation={customValidation}
                onFileSelected={handleFileSelected}
                onValidationPassed={handleValidationPassed}
                onValidationFailed={handleValidationFailed}
                onFileReset={handleFileReset}
                // triggerReset={triggerReset}
                handleSubmit={handleSubmit}
                disabled={!remarks}
                renderTable
              />
            </div>
          </div>
        </div>
    </>
  );
}

export default WhitelistRecs;
