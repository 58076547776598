import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious
} from "../ui/pagination";

const LmfsPagination = ({
  handlePagination,
  totalCount,
  activePage,
  itemsPerPage = 10
}) => {
  const totalPages = Math.ceil(totalCount / itemsPerPage);
  const generatePaginationItems = () => {
    const paginationItems = [];
    const maxVisiblePages = 6; // Number of visible pages around the active page

    if (totalPages <= maxVisiblePages) {
      // If total pages are small, show all
      for (let i = 1; i <= totalPages; i++) {
        paginationItems.push(renderPageItem(i));
      }
    } else {
      // Always show first and last page
      paginationItems.push(renderPageItem(1));

      // Show ellipses if needed before the range
      if (activePage > 3) {
        paginationItems.push(<PaginationEllipsis key="left-ellipses" />);
      }

      // Show a range of pages around the active page
      const start = Math.max(2, activePage - 1);
      const end = Math.min(totalPages - 1, activePage + 1);

      for (let i = start; i <= end; i++) {
        paginationItems.push(renderPageItem(i));
      }

      // Show ellipses if needed after the range
      if (activePage < totalPages - 2) {
        paginationItems.push(<PaginationEllipsis key="right-ellipses" />);
      }

      // Always show last page
      paginationItems.push(renderPageItem(totalPages));
    }

    return paginationItems;
  };

  const renderPageItem = page => (
    <PaginationItem key={page}>
      <PaginationLink
        isActive={page === activePage}
        onClick={page === activePage ? null : () => handlePagination(page)}
        href="#"
        className={page === activePage ? "pointer-events-none" : ""}
      >
        {page}
      </PaginationLink>
    </PaginationItem>
  );

  const getStartingResultIndex = () => (activePage - 1) * itemsPerPage + 1;
  const getEndingResultIndex = () =>
    Math.min(activePage * itemsPerPage, totalCount);
  const showPagination = totalPages > 1;
  return (
    <Pagination className={"border-t justify-between p-4 px-3"}>
      {showPagination ? (
        <div className="flex">
          <PaginationContent>
            {activePage > 1 && (
              <PaginationItem>
                <PaginationPrevious
                  onClick={() => handlePagination(activePage - 1)}
                  href="#"
                />
              </PaginationItem>
            )}
            {generatePaginationItems()}
            {activePage < totalPages && (
              <PaginationItem>
                <PaginationNext
                  onClick={() => handlePagination(activePage + 1)}
                  href="#"
                />
              </PaginationItem>
            )}
          </PaginationContent>{" "}
        </div>
      ) : null}
      <div className="ml-auto">
        Showing {getStartingResultIndex()} to {getEndingResultIndex()} of{" "}
        {totalCount} Results
      </div>
    </Pagination>
  );
};

export default LmfsPagination;
