import { useState, useCallback, useEffect } from "react";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Alert, AlertDescription, AlertTitle } from "../ui/alert";
import { AlertCircle, FileUp, X } from "lucide-react";
import CSVTable from "./CsvViewer";

const FileUploader = ({
  acceptedFileType,
  customValidation,
  onFileSelected,
  onValidationPassed,
  onValidationFailed,
  onFileReset,
  handleSubmit,
  disabled,
  renderTable
}) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);

  const validateFileType = file => {
    if (acceptedFileType === "csv" && !file.name.endsWith(".csv")) {
      return "Please upload a CSV file.";
    }
    // Add more file type validations here as needed
    return null;
  };

  const handleFileChange = event => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setError(null);

    if (selectedFile) {
      onFileSelected(selectedFile);

      const fileTypeError = validateFileType(selectedFile);
      if (fileTypeError) {
        setError(fileTypeError);
        onValidationFailed(fileTypeError);
        return;
      }

      if (customValidation) {
        const customError = customValidation(selectedFile);
        if (customError) {
          setError(customError);
          onValidationFailed(customError);
          return;
        }
      }

      onValidationPassed(selectedFile);
    }
  };

  const resetFile = useCallback(() => {
    setFile(null);
    setError(null);
    onFileReset();
    const fileInput = document.getElementById("file-upload");
    if (fileInput) {
      fileInput.value = "";
    }
  }, [onFileReset]);

  const handleFileSubmit = async () => {
    try {
      await handleSubmit(file);
    } catch (error) {
      console.log("ee", error);
    }
    resetFile();
  };
  return (
    <div className="row ml-2 ">
      <div className="space-y-4">
        <Label htmlFor="file-upload">
          Upload {acceptedFileType.toUpperCase()} File
        </Label>
        <Input
          id="file-upload"
          type="file"
          accept={`.${acceptedFileType}`}
          onChange={handleFileChange}
          className="!pb-11 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100 !block"
        />
        <Button
          onClick={() => {
            handleFileSubmit();
          }}
          disabled={disabled || !file}
          className="w-full mt-2"
        >
          Submit
        </Button>
        {file && !error && (
          <Alert className="bg-green-50 text-green-700 border-green-200">
            <FileUp className="h-4 w-4" />
            <AlertTitle>File Uploaded</AlertTitle>
            <AlertDescription className="flex justify-between items-center">
              <span>{file.name} has been successfully uploaded.</span>
              <Button
                variant="ghost"
                size="icon"
                onClick={resetFile}
                className="h-8 w-8 p-0"
              >
                <X className="h-4 w-4" />
              </Button>
            </AlertDescription>
          </Alert>
        )}
        {error && (
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
      </div>
      {renderTable && (
        <div className="ml-2">
          <CSVTable file={file} />
        </div>
      )}
    </div>
  );
};

export default FileUploader;
