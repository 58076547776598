import { CSVLink } from "react-csv";
import moment from "moment";

const ExportToCSV = ({
  csvHeaders = [],
  dataList,
  ctaText = "Export Leads",
  fileName = "js-leads"
}) => {
  const csvFilename = `${fileName}-${moment(new Date()).format(
    "YYYY-MM-DD-hh_mm_ss"
  )}.csv`;

  return (
    <>
      <CSVLink
        data={dataList}
        csvHeaders={csvHeaders ? csvHeaders : null}
        filename={csvFilename}
        className="download_link"
        target="_blank"
      >
        {ctaText}
      </CSVLink>
    </>
  );
};
export default ExportToCSV;
