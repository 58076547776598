import Dashboard from "../components/Dashboard";
import JobsDashboard from "../components/Jobs/JobsDashboard";
import JobListContainer from "../components/Jobs/JobListContainer";
import LiveJobList from "../components/Jobs/LiveJobList";
import SearchDuplicateCompany from "../components/Jobs/SearchDuplicateCompany";
import AgentLeads from "../components/Jobs/AgentLeads";
import AgentLeadsEditForm from "../components/Jobs/AgentLeadsEditForm";
import AnalyticDashboard from "../components/Analytics/AnalyticDashboard";
import JobSeeker from "../components/JobSeeker/JobSeeker";
import AppliedJobs from "../components/JobSeeker/AppliedJobs";
import ReportedJobSeeker from "../components/JobSeeker/ReportedJobSeeker";
import EditJobSeeker from "../components/JobSeeker/EditJobSeeker";
import CrawlingV2 from "../components/Crawling/CrawlingV2";
import RecruiterDashboard from "../components/Recruiters/RecruiterDashboard";
import LeadsTab from "../components/Recruiters/LeadsTab";
import LeadsForm from "../components/Recruiters/LeadsForm";
import RecommendedJobs from "../components/Recruiters/RecommendedJobs";
import Recruiter from "../components/Recruiters/Recruiter";
import Applications from "../components/Recruiters/Applications";
import JSDetails from "../components/Recruiters/JSDetails";
import ComplaintsDashboard from "../components/Complaints/Dashboard";
import ReportedJobs from "../components/Complaints/ReportedJobs/ReportedJobs";
import UnverifiedRecruiter from "../components/Recruiters/Unverified/UnverifiedRecruiter";
import ModeratorAgentActivity from "../components/Analytics/ModeratorAgentActivity";
import DashboardReports from "../components/Analytics/DashboardReports/Reports";
import CrawledRecruiter from "../components/Jobs/Crawled/CrawledRecruiter";
import OperationTrackingReport from "../components/Analytics/OperationTracking/OperationTrackingReport";
import AdminPannel from "../components/Analytics/AdminPannel/AdminPannel";
import ModeratorAssignment from "../components/Analytics/ModeratorAssignment";
import ModeratorAttendance from "../components/Analytics/ModeratorAttendance";
import ToolsDashboard from "../components/Tools";
import URLShortener from "../components/Tools/URLShortener";
import LearnTabVideo from "../components/Tools/LearnTabVideo";
import AddVideo from "../components/Tools/LearnTabVideo/AddVideo";
import ManualJDAuditing from "../components/Jobs/ManualJDAuditing";
import EnterpriseLeadsDashboard from "../components/EnterpriseLeads";
import ViewLeads from "../components/EnterpriseLeads/ViewLeads";
import Performance from "../components/EnterpriseLeads/Performance";
import FraudsKeywords from "../components/Complaints/FraudsKeywords";
import WorkBench from "../components/Tools/Workbench";
import LcsCampaignCreation from "../components/lcsCampaignQuestions";
import CampaignDashboard from "../components/Campaign";
import TransactionHistory from "../components/TransactionHistory";
import DynamicDagBuilder from "../components/Tools/DynamicDagBuilder";
import AddDagBuilder from "../components/Tools/DynamicDagBuilder/AddDagBuilder";
import Moderators from "../components/Moderators";
import CatAssignmentVariables from "../components/Analytics/ModeratorAssignment/CatAssignmentVariables";
import ViewCampaign from "../components/Campaign/ViewCampaign";
import TagCategoryList from "../components/Tools/LearnTabVideo/TagCategoryList";
import AddTagCategory from "../components/Tools/LearnTabVideo/AddTagCategory";
import LeadSharingDistribution from "../components/EnterpriseLeads/LeadSharingDistribution";
import EnterpriseBulkJobPosting from "../components/EnterpriseLeads/EnterpriseBulkJobPosting";
import Leads from "../componentsV2/local/Leads";
import Companies from "../componentsV2/local/Companies";
import Sales from "../componentsV2/local/Sales";
import LeadAssignment from "../componentsV2/local/LeadAssignment";
import AddLead from "../componentsV2/local/Leads/AddLead";
import LeadRecruiterDetails from "../componentsV2/local/LeadRecruiterDetails";
import RestrictValueDelivery from "../components/RestrictValueDelivery";
import RaiseRequest from "../components/RestrictValueDelivery/RaiseRequest";
import ViewRequest from "../components/RestrictValueDelivery/ViewRequest";
import ViewSingleRequest from "../components/RestrictValueDelivery/ViewSingleRequest";
import AddEditCampaign from "../components/Campaign/AddEditCampaign";
import AutoJobPosting from "../components/Jobs/AutoJobPosting";
import LCSImageGen from "../componentsV2/local/LCSImageGen";

import {
  HOME,
  RECUITERS,
  JOB_SEEKER,
  RECUITERS_APPLICANT,
  RECUITERS_LEADS,
  RECUITERS_LIST,
  JOBS,
  LIVE_JOBS,
  AGENT_LEADS,
  TOOLS,
  COMPLAINTS,
  ANALYTICS,
  URL_SHORTENER,
  LEARN_TAB,
  MANUAL_JD_AUDITING,
  ENTERPRISE_LEADS,
  MODEARTOR_ASSIGNMENT,
  ADMIN_PANEL,
  MODEARTOR_ATTENDANCE,
  FIX_DUPLICATE_PROFILE,
  RECOMMENDED_JOB,
  RECRUITER_JS_DETAILS,
  DASHBOARD_REPORTS,
  OPERATION_TRACKING,
  JOB_CRAWLING,
  CRAWLED_RECRUITER,
  FRAUD_KEYWORDS,
  WORKBENCH,
  TRANSACTION_HISTORY,
  CREDITS_RESERVED,
  CAT_ASSIGNMENT_VARIABLES,
  LCS_CAMPAIGN_CREATION,
  CAMPAIGN_DASHBOARD,
  CREATE_CAMPAIGN,
  VIEW_CAMPAIGN,
  EDIT_CAMPAIGN,
  MODERATOR_LIST,
  DYNAMIC_DAG,
  LEARN_TAB_TAG_CATEGORY,
  LEARN_TAB_TAG_CATEGORY_ADD,
  ENTERPRISE_LEADS_SHAREING,
  VIEW_AUTO_JOB_POSTING,
  LFMS,
  LFMS_COMPANIES,
  LFMS_SALES,
  LFMS_ASSIGNMENT,
  LFMS_ADD_LEAD,
  LFMS_LEAD_DETAILS,
  RESTRICT_VALUE_DELIVERY,
  RAISE_RESTRICT_VALUE,
  VIEW_RESTRICT_VALUE,
  VIEW_SINGLE_REQUEST,
  LCS_IG,
  WHITELIST_RECS,
  ENTERPRISE_BULK_JOB_POSTING
} from "../routes/RouteConstants";
import WhitelistRecs from "../components/Tools/WhitelistRecs";

export const routeConfig = [
  { path: HOME, component: <Dashboard /> },
  { path: JOBS, component: <JobsDashboard /> },
  { path: `${JOBS}/:page/:role`, component: <JobListContainer /> },
  { path: LIVE_JOBS, component: <LiveJobList /> },
  { path: JOB_SEEKER, component: <JobSeeker /> },
  { path: `${JOB_SEEKER}/applied/:uid`, component: <AppliedJobs /> },
  { path: `${JOB_SEEKER}/reported/:uid`, component: <ReportedJobSeeker /> },
  { path: `${JOB_SEEKER}/edit/:uid`, component: <EditJobSeeker /> },
  { path: FIX_DUPLICATE_PROFILE, component: <SearchDuplicateCompany /> },
  { path: RECUITERS, component: <RecruiterDashboard /> },
  { path: RECUITERS_LEADS, component: <LeadsTab /> },
  { path: `${RECUITERS_LEADS}/add`, component: <LeadsForm /> },
  { path: `${RECUITERS_LEADS}/:lead_id`, component: <LeadsForm /> },
  { path: RECUITERS_LIST, component: <Recruiter /> },
  { path: `${RECUITERS_APPLICANT}/:id`, component: <Applications /> },
  { path: RECOMMENDED_JOB, component: <RecommendedJobs /> },
  { path: AGENT_LEADS, component: <AgentLeads /> },
  { path: `${AGENT_LEADS}/:id`, component: <AgentLeadsEditForm /> },
  { path: `${RECRUITER_JS_DETAILS}/:id`, component: <JSDetails /> },
  { path: ANALYTICS, component: <AnalyticDashboard /> },
  { path: COMPLAINTS, component: <ComplaintsDashboard /> },
  { path: `${COMPLAINTS}/:type`, component: <ReportedJobs /> },
  { path: `${RECUITERS}/:type/:role`, component: <UnverifiedRecruiter /> },
  { path: DASHBOARD_REPORTS, component: <DashboardReports /> },
  { path: OPERATION_TRACKING, component: <OperationTrackingReport /> },
  { path: ADMIN_PANEL, component: <AdminPannel /> },
  { path: MODEARTOR_ASSIGNMENT, component: <ModeratorAssignment /> },
  { path: MODEARTOR_ATTENDANCE, component: <ModeratorAttendance /> },
  { path: `${ANALYTICS}/:type`, component: <ModeratorAgentActivity /> },
  { path: `${CRAWLED_RECRUITER}/:role`, component: <CrawledRecruiter /> },
  { path: JOB_CRAWLING, component: <CrawlingV2 /> },
  { path: TOOLS, component: <ToolsDashboard /> },
  { path: URL_SHORTENER, component: <URLShortener /> },
  { path: LEARN_TAB, component: <LearnTabVideo /> },
  { path: LEARN_TAB_TAG_CATEGORY, component: <TagCategoryList /> },
  { path: LEARN_TAB_TAG_CATEGORY_ADD, component: <AddTagCategory /> },
  { path: `${LEARN_TAB}/add`, component: <AddVideo /> },
  { path: `${LEARN_TAB}/:id`, component: <AddVideo /> },
  { path: MANUAL_JD_AUDITING, component: <ManualJDAuditing /> },
  { path: VIEW_AUTO_JOB_POSTING, component: <AutoJobPosting /> },
  { path: ENTERPRISE_LEADS, component: <EnterpriseLeadsDashboard /> },
  {
    path: `${ENTERPRISE_LEADS}/perfromance`,
    component: <Performance />
  },
  { path: `${ENTERPRISE_LEADS}/:type`, component: <ViewLeads /> },
  { path: `${FRAUD_KEYWORDS}/:type`, component: <FraudsKeywords /> },
  { path: WORKBENCH, component: <WorkBench /> },
  { path: TRANSACTION_HISTORY, component: <TransactionHistory /> },
  { path: CREDITS_RESERVED, component: <Recruiter /> },
  { path: CAT_ASSIGNMENT_VARIABLES, component: <CatAssignmentVariables /> },
  { path: LCS_CAMPAIGN_CREATION, component: <LcsCampaignCreation /> },
  { path: CAMPAIGN_DASHBOARD, component: <CampaignDashboard /> },
  { path: CREATE_CAMPAIGN, component: <AddEditCampaign /> },
  { path: VIEW_CAMPAIGN, component: <ViewCampaign /> },
  { path: `${EDIT_CAMPAIGN}/:id`, component: <AddEditCampaign /> },
  { path: DYNAMIC_DAG, component: <DynamicDagBuilder /> },
  { path: `${DYNAMIC_DAG}/add`, component: <AddDagBuilder /> },
  { path: `${DYNAMIC_DAG}/:id`, component: <AddDagBuilder /> },
  { path: MODERATOR_LIST, component: <Moderators /> },
  { path: ENTERPRISE_LEADS_SHAREING, component: <LeadSharingDistribution /> },
  {
    path: ENTERPRISE_BULK_JOB_POSTING,
    component: <EnterpriseBulkJobPosting />
  },

  { path: LFMS, component: <Leads /> },
  { path: LFMS_COMPANIES, component: <Companies /> },
  { path: LFMS_SALES, component: <Sales /> },
  { path: LFMS_ASSIGNMENT, component: <LeadAssignment /> },
  { path: LFMS_ADD_LEAD, component: <AddLead /> },
  { path: LFMS_LEAD_DETAILS, component: <LeadRecruiterDetails /> },
  { path: RESTRICT_VALUE_DELIVERY, component: <RestrictValueDelivery /> },
  { path: RAISE_RESTRICT_VALUE, component: <RaiseRequest /> },
  { path: VIEW_RESTRICT_VALUE, component: <ViewRequest /> },
  { path: VIEW_SINGLE_REQUEST, component: <ViewSingleRequest /> },
  //LCS BANNER GENERATOR
  { path: LCS_IG, component: <LCSImageGen /> },
  { path: WHITELIST_RECS, component: <WhitelistRecs /> }
];
