import JobHai from "../api/JobHai";
import { getErrorMessage } from "./utils";

const whitelistWithCsvData = async (file, remarks) => {
  try {
    const formData = new FormData();
    formData.append("file", new File([file], file.name, { type: "text/csv" }));

    // formData.append("file", file); 
    formData.append("remarks", remarks);


    const response = await JobHai.post("/update-whitelist-orgs-and-recruiters", formData);
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

export {whitelistWithCsvData}