import { useState } from "react";
import UploadEnterpriseBulkJob from "./UploadEnterpriseBulkJob";
import JobHai from "../../../api/JobHai";
import { getCustomErrorMessage } from "../../../services/utils";
import ExportToCSV from "../ExportToCSV";
import LoadingSpinner from "../../LoadingSpinner";

const EnterpriseBulkJobPostingFilter = ({}) => {
  const [jobPostingType, setJobPostingType] = useState("");
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [file, setFile] = useState({});
  const [uploadedJobs, setUploadedJobs] = useState([]);
  const [isResetClick, setIsResetClick] = useState(false);

  const handleChange = e => {
    setJobPostingType(e.target.value);
  };

  const onFileUpload = async file => {
    if (file) {
      setFile(file);
    }
  };

  const onFileUploadSuccess = async () => {
    setIsUploadLoading(true);
    if (file.name && jobPostingType) {
      const formData = new FormData();

      formData.append("file", file);
      formData.append("type", jobPostingType);

      try {
        const res = await JobHai.post(
          "/enterprise-job/post-job-using-csv",
          formData
        );
        setSuccess("Saved");
        setIsUploadLoading(false);

        const uploadedJobsData = res.data.data;
        const convertedArray = uploadedJobsData?.data?.map(obj => {
          return Object.fromEntries(
            Object.entries(obj).map(([key, value]) => [key, String(value)])
          );
        });

        setUploadedJobs(convertedArray);
      } catch (error) {
        let msg = getCustomErrorMessage(error);
        setErrorMsg(msg);
        setIsUploadLoading(false);
      }
    } else {
      setIsUploadLoading(false);
      setErrorMsg("Please select type and upload a correct .csv file");
    }
  };

  // const onResetData = () => {
  //   setFile({});
  //   setJobPostingType("");
  //   setIsResetClick(true);
  //   setUploadedJobs([])
  // };

  const isEnabled = jobPostingType && file.name;
  return (
    <div className="job_seeker recruiter-search" style={{ minHeight: "400px" }}>
      <div className="clr gap-20" />
      <div className="clr gap-20" />

      <div className="row justify-between">
        <div className="col-md-2  mt-2">
          <b className="downArrow" />
          <select
            className="form-control"
            name="jobPostingType"
            value={jobPostingType}
            onChange={handleChange}
          >
            <option value="">Job Posting Type</option>
            <option value="enterprise_job">Enterprise Job Posting</option>
            <option value="bulk_job">Bulk Job Posting</option>
          </select>
        </div>

        <div className="col-md-6 text-right">
          <UploadEnterpriseBulkJob
            isError={errorMsg}
            onFileUpload={onFileUpload}
            isResetClick={isResetClick}
          />
          {errorMsg ? <span className="text-danger">{errorMsg} </span> : null}
          {/* <div className="download_link" onClick={onResetData}>
            Reset
          </div> */}

          {isUploadLoading ? (
            <LoadingSpinner key="loading" />
          ) : (
            <>
              {uploadedJobs?.length > 0 && (
                <ExportToCSV
                  dataList={uploadedJobs}
                  ctaText={"Export Jobs"}
                  fileName={
                    jobPostingType === "enterprise_job"
                      ? "enterprise-job-posting"
                      : "bulk-job-posting"
                  }
                />
              )}
              <button
                className="btn btn-primary"
                disabled={!isEnabled}
                onClick={onFileUploadSuccess}
              >
                Upload Jobs
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default EnterpriseBulkJobPostingFilter;
