import React from "react";
import {
  getErrorMessage,
  isEditedFields,
  getFormattedValue,
  findChipValue,
  findBooleanState
} from "../../services/utils";
import JobHai from "../../api/JobHai";
import {
  blackListRecruiterReason,
  booleanOptions,
  recruiter_consultant,
  recruiterBucket
} from "../../constants/MetaData";
import Heading from "../Common/Form/Heading";
import Label from "../Common/Form/Label";
import CustomTextField from "../Common/Form/CustomTextField";
import CustomSelect from "../Common/Form/CustomSelect";
import RecruiterCallAttempt from "./RecruiterCallAttempt";
import Chips from "../Common/Chips";
import NewTag from "../Common/NewTag";
import moment from "moment/moment";

class RecruiterDetails extends React.Component {
  handleCheckBox = event => {
    const name = event.currentTarget.name;
    const checked = event.target.checked;
    this.props.getFieldData(name, checked);
  };

  handleChange = event => {
    let { name, value } = event.target;
    const { getFieldData, blacklist_reason_id } = this.props;
    if (name === "full_name")
      value = getFormattedValue(value, false, false, true);
    if (name === "decision_maker_number")
      value = getFormattedValue(value, true, true);
    if (name === "denial_reason") {
      // 2 is value of Manual By Moderator
      const reasonId = value === "BLACKLIST" ? 2 : blacklist_reason_id || null;
      getFieldData("blacklist_reason_id", reasonId);
    }
    getFieldData(name, value);
  };

  handleCallStatus = (fieldName, value) => {
    this.props.getFieldData(fieldName, value);
  };

  updateMessage = (msg, flag, relaod = false) => {
    this.props.statusMessage(msg, flag, relaod);
  };

  sendVerificationEmail = e => {
    e.preventDefault();
    const compData = this.props.data;
    const obj = {
      recruiter_id: compData.recruiter_id
    };
    if (window.confirm("Are you sure you want to send verification email?")) {
      JobHai.post("/send_verification_email", obj)
        .then(() => {
          this.updateMessage("Verification email has been sent.", 0);
        })
        .catch(error => {
          let msg = getErrorMessage(error);
          this.updateMessage(msg, 1);
        });
    }
  };

  handleDecisionMaker = (event, fieldName, item) => {
    const { data, getFieldData } = this.props;
    const { is_rec_decision_maker } = data;
    const { label } = item;
    getFieldData(
      fieldName,
      !findChipValue(is_rec_decision_maker) ? findBooleanState(label) : null
    );
  };

  render() {
    const {
      editedFields = [],
      isEmailBounced = false,
      bounceReason = "",
      data,
      recEmail
    } = this.props;
    const {
      full_name,
      is_email_verified,
      email,
      phone,
      denial_reason,
      is_whatsapp_enabled,
      recruiter_calling_first_status = "NEW",
      recruiter_calling_second_status = "NEW",
      recruiter_calling_third_status = "NEW",
      posted_jobs_in_new_city_launch = false,
      recent_email_data = {},
      recruiter_type,
      blacklist_reason_id = "",
      is_rec_decision_maker = null,
      decision_maker_name = "",
      decision_maker_number = "",
      is_new_city_recruiter,
      recruiter_whitelisted_on,
      is_recruiter_whitelisted
    } = data;

    const callStatusValue = {
      recruiter_calling_first_status,
      recruiter_calling_second_status,
      recruiter_calling_third_status
    };
    const isNewCity = posted_jobs_in_new_city_launch; //posted_jobs_in_new_city_launch;
    const isRecruiterEdited =
      isEditedFields(editedFields, "full_name") ||
      isEditedFields(editedFields, "email") ||
      isEditedFields(editedFields, "phone");

    const activeDecisionMakerChip = {
      id: findChipValue(is_rec_decision_maker),
      label: findChipValue(is_rec_decision_maker)
    };
    return (
      <>
        <Heading
          heading="Recruiter Details"
          isNewCity={isNewCity}
          isEdited={isRecruiterEdited}
        >
          <div className="inline-block space-x-2">
          {is_new_city_recruiter ? <NewTag /> : null}
          {is_recruiter_whitelisted? <NewTag label={`Whitelisted (${recruiter_whitelisted_on?moment(recruiter_whitelisted_on).format("DD-MM-YY"):null})`}/>:null}
          </div>
        </Heading>
        <div className="formRow">
          <div className="left">
            <Label
              lable="Recruiter Name"
              isEdited={isEditedFields(editedFields, "full_name")}
            />
            <CustomTextField
              name="full_name"
              value={full_name}
              onChange={this.handleChange}
            />
          </div>
          <div className={is_email_verified ? "right verifiedEmail" : "right"}>
            <Label
              lable="Recruiter Email"
              isEdited={isEditedFields(editedFields, "email")}
            />
            <CustomTextField
              name="email"
              value={email}
              onChange={this.handleChange}
              maxLength="60"
              endAdornment={
                is_email_verified ? (
                  <span className="emailVerified">
                    <img
                      src="/images/verified.svg"
                      alt="verified"
                      width="12"
                      height="9"
                    />
                    Verified
                  </span>
                ) : (
                  <>
                    {recEmail ? (
                      <span
                        className="verifyEmail"
                        onClick={this.sendVerificationEmail}
                      >
                        Verify Email
                      </span>
                    ) : null}
                  </>
                )
              }
            />
            {isEmailBounced && bounceReason ? (
              <div className="email-bounce">
                <img
                  src="/images/warning.svg"
                  alt="mail bounced"
                  width="12"
                  height="12"
                />
                {bounceReason}
              </div>
            ) : null}
            {recent_email_data?.email ? (
              <div className="recDetails">
                Last Email:{" "}
                <span>
                  {recent_email_data.email}{" "}
                  {recent_email_data.verification_status === "VERIFIED" ? (
                    <img
                      src="/images/verified.svg"
                      alt="verified"
                      width="12"
                      height="9"
                    />
                  ) : null}
                </span>
              </div>
            ) : null}
          </div>
        </div>
        <div className="formRow">
          <div className="left">
            <Label
              lable="Contact Number"
              isEdited={isEditedFields(editedFields, "phone")}
            />
            <CustomTextField name="phone" value={phone} isReadonly={true} />
            <div className="form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="is_whatsapp_enabled"
                onChange={this.handleCheckBox}
                id="whatsapp_enabled"
                checked={is_whatsapp_enabled ? true : false}
              />
              <label className="form-check-label" htmlFor="whatsapp_enabled">
                Opt-in for WhatsApp
              </label>
            </div>
          </div>
        </div>

        <div className="formRow">
          <div className="left">
            <Label lable="Is recruiter a decision maker ?" isOptional={true} />
            <Chips
              chips={booleanOptions}
              activeChips={activeDecisionMakerChip}
              onClick={this.handleDecisionMaker}
              isClose={true}
              fieldName="is_rec_decision_maker"
              isMulti={false}
            />
          </div>
        </div>
        {activeDecisionMakerChip.id === "No" ? (
          <div className="formRow">
            <div className="left">
              <Label lable="Decision Maker Name" isOptional={true} />
              <CustomTextField
                name="decision_maker_name"
                value={decision_maker_name}
                onChange={this.handleChange}
                placeholder="Add decision maker name"
              />
            </div>

            <div className="right">
              <Label lable="Decision Maker Phone Number" isOptional={true} />
              <CustomTextField
                name="decision_maker_number"
                value={decision_maker_number}
                onChange={this.handleChange}
                placeholder="Add decision maker phone number"
                maxLength={10}
              />
            </div>
          </div>
        ) : null}
        <RecruiterCallAttempt
          callStatusValue={callStatusValue}
          handleCallStatus={this.handleCallStatus}
        />
        <div className="formRow">
          <div className="left">
            <Label lable="Recruiter Bucket" isOptional={true} />
            <CustomSelect
              name="denial_reason"
              value={denial_reason}
              onChange={this.handleChange}
              optionsList={recruiterBucket}
            />
          </div>
          <div className="right">
            <Label lable="Are you hiring for" />
            <CustomSelect
              name="recruiter_type"
              value={recruiter_type}
              onChange={this.handleChange}
              optionsList={recruiter_consultant}
            />
          </div>
        </div>
        {denial_reason === "BLACKLIST" ? (
          <div className="formRow">
            <div className="left">
              <Label lable="Black List Reason" isOptional={true} />
              <CustomSelect
                name="blacklist_reason_id"
                value={blacklist_reason_id}
                onChange={this.handleChange}
                allowDisabled={true}
                optionsList={blackListRecruiterReason}
              />
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

export default RecruiterDetails;
