import { useState, useMemo } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import {
  enterPriseLeadType,
  lcsLeadSource,
  leadPlatform
} from "../../constants/MetaData";
import SelectCity from "../Common/SelectCity";
import SelectLocality from "../Common/SelectLocality";
import {
  findBooleanState,
  getFormattedValue,
  isObjectEmpty
} from "../../services/utils";
import { getLeadCount, getSharedLeads } from "../../services/enterpriseLeads";
import ExportToCSV from "./ExportToCSV";
import UploadSharedLeads from "./UploadSharedLeads";
import AutoSearch from "../Common/Form/AutoSearch";

const defaultFilter = {
  interviewDate: "",
  city_id: "",
  locality_id: "",
  lead_type: "",
  is_lead_shared: "",
  is_user_shared: "",
  shared_with_kam_id: "",
  phone: "",
  lead_source: "",
  source: "",
  enterviewDate: "",
  campaign_id: ""
};
const LeadsSharingFilter = ({ campaignList = [], kamList = [] }) => {
  const [filters, setFilters] = useState(defaultFilter);
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showCount, setCount] = useState(null);
  const [leadCount, setLeadCount] = useState(null);
  const [leadsList, setLeadsList] = useState([]);
  const [resetKey, setResetKey] = useState(0);

  const isResetAvailable = useMemo(
    () => Object.values(filters).some(value => value),
    [filters]
  );

  const handleChange = ({ target: { name, value } }) => {
    setLeadsList([]);
    if (name === "phone") value = getFormattedValue(value, true, true);
    setFilters(prev => ({ ...prev, [name]: value }));
    resetCount();
  };

  const handleDateChange = (fieldName, value) => {
    setLeadsList([]);
    setFilters(prev => ({ ...prev, [fieldName]: value }));
    resetCount();
  };

  const validateFilters = () => {
    const { phone } = filters;
    const phoneNumberPattern = /^[5-9][0-9]{9}$/;

    if (phone && !phoneNumberPattern.test(phone)) {
      setErrorMsg("Please enter a valid phone number.");
      return false;
    }

    if (isObjectEmpty(filters)) {
      setErrorMsg("Please select from filter list");
      return false;
    }

    return true;
  };

  const formatFilterDates = () => {
    return {
      ...filters,
      interview_date: filters.interviewDate
        ? moment(filters.interviewDate).format("YYYY-MM-DD")
        : null,
      is_lead_shared: filters.is_lead_shared
        ? findBooleanState(filters.is_lead_shared)
        : undefined,
      is_user_shared: filters.is_user_shared
        ? findBooleanState(filters.is_user_shared)
        : undefined
    };
  };

  const fetchLeadCount = async () => {
    setIsLoading(true);
    setErrorMsg(null);
    setLeadsList([]);
    const formattedFilters = formatFilterDates();
    try {
      const response = await getLeadCount(formattedFilters);
      setLeadCount(response?.lead_count || 0);
      setCount(true);
    } catch (error) {
      setCount(false);
      setLeadCount(null);
      setErrorMsg(error);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadLeads = async () => {
    setIsLoading(true);
    const formattedFilters = formatFilterDates();
    setErrorMsg(null);
    try {
      const response = await getSharedLeads(formattedFilters);
      setLeadsList(response?.lead_count || []);
    } catch (error) {
      console.error("Error fetching shared leads:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = e => {
    e.preventDefault();
    // if (validateFilters()) {
    //   fetchLeadCount();
    // }
    fetchLeadCount();
  };

  const resetFilters = () => {
    setFilters(defaultFilter);
    resetCount();
    setLeadsList([]);
    setErrorMsg(null);
    setResetKey(prevKey => prevKey + 1);
  };

  const resetCount = () => {
    setLeadCount(null);
    setCount(false);
    setErrorMsg(null);
  };

  const handleCampaign = obj => {
    const { id = "" } = obj;
    const event = {
      target: {
        value: id,
        name: "campaign_id"
      }
    };
    handleChange(event);
  };

  const getSelectedCampaign = searchInput => {
    if (!searchInput) {
      const event = {
        target: {
          value: "",
          name: "campaign_id"
        }
      };
      handleChange(event);
    }
  };
  const {
    interviewDate,
    city_id,
    locality_id,
    lead_type,
    is_lead_shared,
    is_user_shared,
    shared_with_kam_id,
    phone,
    lead_source,
    source,
    campaign_id
  } = filters;

  const newCampanignList = campaignList.map(item => ({
    ...item,
    label: item.title
  }));
  return (
    <div className="job_seeker recruiter-search" style={{ minHeight: "400px" }}>
      <form autoComplete="off" onSubmit={handleSearch} className="search">
        <div className="clr gap-20" />
        <div className="row">
          <div className="col-md-2">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="phone"
                placeholder="Phone"
                value={phone}
                onChange={handleChange}
                maxLength="10"
              />
            </div>
          </div>
          <div className="col-md-2">
            <SelectCity
              key={resetKey}
              filedName={"city_id"}
              value={city_id}
              onChange={handleChange}
              label={false}
              allCity={true}
            />
          </div>
          <div className="col-md-2">
            <SelectLocality
              key={resetKey}
              filedName={"locality_id"}
              value={locality_id}
              cityId={city_id}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <b
                className="downArrow"
                style={{ padding: "3px", marginTop: "13px" }}
              />
              <select
                className="form-control"
                name="lead_type"
                value={lead_type}
                onChange={handleChange}
              >
                <option value="">Select Lead Type</option>
                {enterPriseLeadType.map((option, index) => (
                  <option value={option.value} key={"lt" + index}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-md-2">
            <AutoSearch
              key={resetKey}
              dataList={newCampanignList}
              getSelectedValue={handleCampaign}
              getSelectedInput={getSelectedCampaign}
              placeholder={"Select Campaign"}
              variant="outlined"
              id="search-campaign"
              error={false}
              searchValue={campaign_id}
              defaultSearch={campaign_id}
              searchType={"array"}
              isArrow={true}
            />
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <b
                className="downArrow"
                style={{ padding: "3px", marginTop: "13px" }}
              />
              <select
                className="form-control"
                name="source"
                value={source}
                onChange={handleChange}
              >
                <option value="">Select Platform</option>
                {leadPlatform.map(option => (
                  <option value={option.id} key={"platform" + option.id}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <div className="form-group">
              <b
                className="downArrow"
                style={{ padding: "3px", marginTop: "13px" }}
              />
              <select
                className="form-control"
                name="lead_source"
                value={lead_source}
                onChange={handleChange}
              >
                <option value="">Select Lead Source</option>
                {lcsLeadSource.map((option, index) => (
                  <option value={option.value} key={"lt" + index}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-md-2">
            <b
              className="downArrow"
              style={{ padding: "3px", marginTop: "13px" }}
            />
            <select
              className="form-control"
              name="is_lead_shared"
              value={is_lead_shared}
              onChange={handleChange}
            >
              <option value="">Lead Shared</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div className="col-md-2">
            <b
              className="downArrow"
              style={{ padding: "3px", marginTop: "13px" }}
            />
            <select
              className="form-control"
              name="is_user_shared"
              value={is_user_shared}
              onChange={handleChange}
            >
              <option value="">Lead User Shared</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div className="col-md-2">
            <b
              className="downArrow"
              style={{ padding: "3px", marginTop: "13px" }}
            />
            <select
              className="form-control"
              name="shared_with_kam_id"
              value={shared_with_kam_id}
              onChange={handleChange}
            >
              <option value="">Lead Shared By KAM</option>
              {kamList.map(option => (
                <option value={option.id} key={`kam${option.id}`}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-2">
            <DatePicker
              selected={interviewDate}
              onChange={e => {
                handleDateChange("interviewDate", e);
              }}
              placeholderText="Interview Date"
              dateFormat="yyyy-MM-dd"
              className="form-control"
              maxDate={new Date()}
            />
          </div>
        </div>

        <div className="clr gap-20" />
        <div className="row">
          <div className="col-md-6">
            {showCount ? <b>Total Lead Count is {leadCount}</b> : null}
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12 text-right">
                {errorMsg ? (
                  <span className="text-danger">{errorMsg} </span>
                ) : null}
                {isResetAvailable ? (
                  <div className="download_link" onClick={resetFilters}>
                    Reset Filter
                  </div>
                ) : null}
                {leadsList.length > 0 && <ExportToCSV dataList={leadsList} />}
                <button
                  className="btn btn-primary"
                  disabled={isLoading}
                  onClick={leadCount ? downloadLeads : handleSearch}
                >
                  {isLoading
                    ? "LOADING..."
                    : leadCount
                    ? "Download Leads"
                    : "Fetch Leads  "}
                </button>
                <UploadSharedLeads isError={errorMsg} />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default LeadsSharingFilter;
