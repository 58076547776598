import React, { useEffect, useState } from "react";
import { COMPANY_EDIT_PAGE } from "../../../routes/RouteConstants";
import CrawlCompanyName from "./CrawlCompanyName";
import CrawlCallingStatus from "./CrawlCallingStatus";
import CrawlStatus from "./CrawledStatus";
import moment from "moment";

const CrawledRecruiterList = props => {
  const {
    handlePostJob,
    handleChange,
    handleSave,
    role = "CLEANER",
    dataList = {},
    savedId,
    successMessage,
    errorMsg,
    saveBtnDisabled,
    bucketId = "",
    initailRecList = {},
    isCurrentSelected,
    callBack,
    showCheckBox,
    isCatAdmin = false
  } = props;
  const itemList = { ...dataList };
  const {
    recruiter_id,
    job_id = "",
    city = "",
    full_name = "",
    pending_jobs = 0,
    phone = "",
    last_assigned_moderator,
    crawled_reassignment_date,
    recruiter_source = "",
    assigned_moderator_name = ""
  } = itemList;

  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    setIsSelected(isCurrentSelected);
  }, [isCurrentSelected]);

  const onItemSelect = e => {
    const isChecked = e.target.checked;
    setIsSelected(isChecked);
    handleChange(recruiter_id, e);
  };

  return (
    <tr className="listvalue crawled-rec-list">
      {showCheckBox ? (
        <td>
          <input
            type="checkbox"
            name="select-row"
            checked={isSelected}
            onChange={onItemSelect}
          />
        </td>
      ) : null}
      <td>
        {recruiter_id}
        <div className="reassignFrom">
          {last_assigned_moderator && isCatAdmin ? (
            <p>
              Reassigned from: {last_assigned_moderator}
              {crawled_reassignment_date
                ? ` on ${moment(crawled_reassignment_date).format(
                    "YYYY-MM-DD"
                  )}`
                : ""}
            </p>
          ) : null}
          <p className="font-normal">Source: {recruiter_source || "NA"}</p>
          <p className="font-normal">
            Moderator Name: {assigned_moderator_name || "NA"}
          </p>
        </div>
      </td>
      <td>
        <CrawlCompanyName item={itemList} />
      </td>
      <td valign="top">{full_name}</td>
      <td>{city ? city : "NA"}</td>
      <td valign="top">
        <div className="edit_link">
          {job_id ? (
            <a
              href={`${COMPANY_EDIT_PAGE}/crawled/${role}/${job_id}?recId=${recruiter_id}`}
              className="primary"
              target="_blank"
              rel="noopener noreferrer"
            >
              {pending_jobs}
            </a>
          ) : (
            <span className="marginL10">0</span>
          )}
        </div>
      </td>
      <td valign="top">{phone ? phone : "NA"}</td>
      <td valign="top">
        <CrawlCallingStatus
          item={itemList}
          handleChange={handleChange}
          initailRecList={initailRecList}
          bucketId={bucketId}
        />
      </td>
      <td>
        <CrawlStatus
          item={itemList}
          handleChange={handleChange}
          handleSave={handleSave}
          savedId={savedId}
          successMessage={successMessage}
          errorMsg={errorMsg}
          saveBtnDisabled={saveBtnDisabled}
          handlePostJob={handlePostJob}
          callBack={callBack}
        />
      </td>
    </tr>
  );
};
export default CrawledRecruiterList;
