import { useEffect, useState } from "react";
import UploadFiles from "../../Common/Form/UploadFiles";

const UploadEnterpriseBulkJob = ({
  isError = "",
  onFileUpload,
  isResetClick = false
}) => {
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null || isError);

  useEffect(() => {
    if (isResetClick) {
      setSuccess(null);
      setError(null);
    }
  }, [isResetClick]);

  const handleFileUpload = async event => {
    const { type = "", files = [] } = event.target;
    setError(null);

    if (type === "file" && files.length) {
      const file = files[0];
      const fileSize = file.size;
      const fileName = file.name;
      const fileTypeRegex = /(.*?)\.(csv)$/;

      if (!fileName.match(fileTypeRegex)) {
        setError("Please upload a CSV file.");
      } else if (fileSize > 2097152) {
        setError("Please upload a file size below 2MB.");
      } else {
        const reader = new FileReader();

        onFileUpload(file);
        setSuccess("File uploaded successfully");

        reader.onerror = () => {
          setError("Failed to read file. Please try again.");
        };

        reader.readAsText(file);
      }
    }
  };
  return (
    <div className="uploadCSV">
      {error && <div className="text-danger mt-1.5">{error}</div>}
      {!error && success ? (
        <div className="text-success mt-1.5">{success}</div>
      ) : null}

      <UploadFiles
        handleFileUpload={handleFileUpload}
        fieldLabel={`Upload Jobs CSV`}
      />
    </div>
  );
};
export default UploadEnterpriseBulkJob;
