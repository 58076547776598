import React, { useEffect, useState } from "react";

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../ui/table"; // Shadcn Table

const CSVTable = ({file}) => {
  const [tableData, setTableData] = useState([]);
  const [headers, setHeaders] = useState([]);



  useEffect(() => {
    if (!file) return;

    const parseFile = async () => {
      const text = await file.text();
      const rows = text.split("\n").map((row) => row.split(","));

      setHeaders(rows[0]);
      setTableData(rows.slice(1));
    };

    parseFile();
  }, [file]);
  if(!file) return null;
  return (
    <div className="p-6 bg-gray-100 ">
      <div className="max-w-3xl mx-auto bg-white shadow-md rounded-md p-6">
        <h1 className="text-2xl font-semibold mb-4">Uploaded File</h1>
        {headers.length > 0 && (
          <Table className="table-auto w-full border border-gray-300">
            <TableHeader>
              <TableRow>
                {headers.map((header, index) => (
                  <TableHead key={index} className="font-bold text-left">
                    {header.trim()}
                  </TableHead>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {tableData.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <TableCell key={cellIndex} className="border border-gray-300 px-2 py-1">
                      {cell.trim()}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default CSVTable;
