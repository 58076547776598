import { ENTERPRISE_LEADS } from "../../../routes/RouteConstants";
import { checkPermission } from "../../../services/utils";
import HeadingRow from "../../Common/HeadingRow";
import NoAccess from "../../Common/NoAccess";
import EnterpriseBulkJobPostingFilter from "./EnterpriseBulkJobPostingFilter";

const EnterpriseBulkJobPosting = () => {
  const isAccess =
    checkPermission("EnterpriseBulkPosting") ||
    checkPermission("EnterpriseLeadsAdmin");

  if (!isAccess) return <NoAccess />;

  return (
    <>
      <HeadingRow
        heading="Enterprise/Bulk Job Posting"
        goBackURL={ENTERPRISE_LEADS}
      />
      <EnterpriseBulkJobPostingFilter />
    </>
  );
};

export default EnterpriseBulkJobPosting;
